import { DateTime } from 'luxon'

export enum ActiveCampaign {
  myTenNights = 'mtn2024',
  myTenDays = 'mtd2024'
}

export module CampaignSetup {
  // Even though actual Ramadan start date is 2024-03-11 (that is fasting starts from day 2024-03-11), we set below start date as 2024-03-10 as the night precedes day in muslim calendar,
  // the below date represents the actual 1st night payment
  export const defaultRamadanStartDate = DateTime.fromISO('2024-03-10')

  export const defaultDhulHijjahStartDate = DateTime.fromISO('2024-06-07')

  export const daysToUnlockUI = 16

  export const currentCampaignName = 'MyTenNights 2024'

  export const currentCampaign = ActiveCampaign.myTenNights

}
