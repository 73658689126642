import { type FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { Button, CircleChecked, CircleExclamation, Page, Spinner } from '@lib/components'
import { displayCurrency } from '@lib/services'
import { Card, loadSchedule, NotFoundError, PaymentStatus as Status, type Schedule, getStripe } from '@shamaazi/mytennights'
import { Header, WithFooter } from '~/components'
import { DateTime } from 'luxon'

export const ScheduleNight: FC = () => {
  const { scheduleID, nightNumber } = useParams<{ scheduleID: string, nightNumber: string }>()
  const { isLoading, isError, error, data: schedule } = useQuery<Schedule>(['schedules', scheduleID], async () => loadSchedule(scheduleID), { retry: 0 })
  const queryClient = useQueryClient()

  const night = schedule?.payments?.find((n) => parseInt(nightNumber) === n.number)

  const { isLoading: retryLoading, isError: isRetryError, mutate: retryPayment } = useMutation(async (): Promise<string> => {
    const stripe = await getStripe(schedule!.charity_id)
    if (stripe === null) {
      throw new Error('Stripe object was not properly initialised')
    }
    const result = await stripe?.confirmCardPayment(night?.paymentIntentSecret ?? '', {
      payment_method: schedule?.paymentMethod
    })

    if (result?.error != null) {
      throw new Error(result?.error?.message)
    }

    return result?.paymentIntent?.status ?? ''
  }, {
    onSuccess: (data) => {
      const payments = schedule?.payments?.map((p) => {
        return p.number !== parseInt(nightNumber) ? p : { ...p, status: data }
      })

      queryClient.setQueryData(['schedules', scheduleID], {
        ...schedule,
        payments
      })
    }
  })

  return <WithFooter>
    <Header />
    <div>
      <div className="mx-6 mt-10">
        <Link className="underline" to={`/account/schedules/${scheduleID}`}>&lt; Back to Donation</Link>
      </div>
      <Page className="px-10 py-10 font-medium lg:grid lg:gap-x-20 lg:grid-cols-fr-auto">
        <div>
          <h1 className="text-xl text-mtn-blue">Night #{nightNumber}</h1>
          {isLoading && <div className="flex justify-center mt-20">
            <Spinner />
          </div>}
          {isError && error instanceof NotFoundError && <div className="mt-10">
            <h2>Sorry, we couldn&apos;t find this donation.<br />Please check you are logged in as the correct email address</h2>
          </div>}
          {isError && !(error instanceof NotFoundError) && <div className="mt-10">
            <h2>Sorry, we weren't able to load your schedule. Please try again later and if
              the issue persists, <Link className="underline text-mtn-blue" to="/contact">contact us</Link>
            </h2>
          </div>}
          {!isLoading && !isError && schedule !== undefined && night !== undefined && <>
            <p className="my-4 text-mtn-blue-800">{night.date.toLocaleString(DateTime.DATE_MED)}</p>
            {night.status === Status.Cancelled && <p className="flex items-center my-2 text-sm text-mtn-red"><CircleExclamation className="mr-4" /> Payment Cancelled</p>}
            {night.status === Status.Failed && <p className="flex items-center my-2 text-sm text-mtn-red"><CircleExclamation className="mr-4" /> Payment Failed</p>}
            {night.status === Status.Succeeded && <p className="flex items-center my-2 text-sm text-mtn-blue"><CircleChecked className="mr-4" /> Payment succeeded</p>}
            {night.status !== Status.Failed && night.status !== Status.Succeeded && night.status !== Status.Cancelled &&
              <span className="flex items-center my-3 text-sm"><CircleExclamation className="mr-4" /> Payment {night.status}</span>}

            <hr className="mt-10 text-mtn-gray-300" />
            <section className="grid items-center my-10 gap-y-6 gap-x-10 grid-cols-auto-fr">
              <span className="text-sm">Charity</span>
              <span className="text-right text-mtn-blue-800">{schedule.charity_name}</span>
              <span className="self-start mt-2 text-sm">Causes</span>
              <div>
                {night.breakdown.causes.map(c => <div key={c.name} className="grid mt-2 gap-x-6 grid-cols-fr-auto">
                  <span className="text-right">{c.name}</span>
                  <span className="text-right text-mtn-blue-800">{displayCurrency({ amount: c.amount, currency: schedule.currency })}</span>
                </div>)}
              </div>
              <span className="text-sm">MyTenNights Donation</span>
              <span className="text-right text-mtn-blue-800">{displayCurrency({ amount: night.breakdown.tip, currency: schedule.currency })}</span>
              <span className="text-sm">Transaction Fees</span>
              <span className="text-right text-mtn-blue-800">{displayCurrency({ amount: schedule.payFees ? night.breakdown.fee : 0, currency: schedule.currency })}</span>
              <hr className="col-span-2 text-mtn-gray-300" />
              <span className="text-sm">Total</span>
              <span className="font-bold text-right text-mtn-blue-800">{displayCurrency({ amount: night.total, currency: schedule.currency })}</span>
              <hr className="col-span-2 text-mtn-gray-300" />
            </section>
          </>}
        </div>
        <div className="flex flex-col">
          <Card variant="mtn" className="mt-20 -mx-6 lg:mx-0 lg:w-96 md:mt-0">
            {isLoading && <section className="flex items-center justify-center p-7"><Spinner /></section>}
            {!isLoading && !isError && schedule !== undefined && <>
              {schedule.cardBrand !== '' && <section className="p-7">
                <div>Paying with card: {schedule.cardBrand} ending {schedule.last4CardDigits}</div>
                <div className="mt-3">Expires {schedule.cardExpiry}</div>
              </section>}
              {schedule.cardBrand === '' && <section className="p-7">
                <div>Paying with unknown card</div>
              </section>}
              {schedule.actionable && schedule.cardBrand !== '' && night?.status === Status.Failed && <section className="border-t p-7 border-mtn-gray-300">
                <div className="flex justify-center">
                  <Button disabled={retryLoading} loading={retryLoading} onClick={() => retryPayment()} variant="mtn-primary">Retry Payment</Button>
                </div>
                {isRetryError && <p className="mt-3 text-sm text-mtn-red">
                  Sorry, we were unable to complete your payment. Please try again and if the issue persists <Link className="underline" to="/contact">
                  contact us.
                </Link>
                </p>}
              </section>}
            </>}
          </Card>
        </div>
      </Page>
    </div>
  </WithFooter>
}
