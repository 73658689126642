import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { isLive } from '@shamaazi/mytennights'
import App from './App'
import { getShamaaziEnvironmentFromURL, initSentryInBrowser, type ShamaaziEnvironment } from '@lib/services'

// Figure out environment from URL
const environment: ShamaaziEnvironment | undefined = getShamaaziEnvironmentFromURL()

// Prevent search engine spiders from crawling the website
if (!isLive) {
  const meta = document.createElement('meta')
  meta.name = 'robots'
  meta.content = 'noindex'
  document.querySelector('head')?.appendChild(meta)
}

// Initialize Sentry (crash analytics)
const dsn: string = 'https://969a9a4a29bfd1b348d7ba1280936618@o4505828020125696.ingest.sentry.io/4506076852191232'
// - control which URLs distributed tracing should be enabled for
const tracePropagationTargetsStaging = [/^https:\/\/.+\.mytennights\.shamaazi\.io\/api/] // private envs & staging
const tracePropagationTargetsProduction = ['https://mytennights.shamaazi.com/api']
initSentryInBrowser(environment, dsn, tracePropagationTargetsStaging, tracePropagationTargetsProduction)

// Render React App
ReactDOM.render(
  <StrictMode>
    <App/>
  </StrictMode>,
  document.getElementById('root')
)
