import { Page, Spinner } from '@lib/components'
import { type FC } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ActiveCampaign, loadCharityCampaign, useCheckoutPlan, MTNtracking, isCampaignOpen } from '@shamaazi/mytennights'
import { CharityLogo, Header, Navigation, SubscribeForm, WithFooter } from '~/components'
import { Split } from './home/Split'
import { LandingPageContent } from './home'

export const CharityLanding: FC<{ nextPage: string }> = ({ nextPage }) => {
  const history = useHistory()
  const { charityID } = useParams<{ charityID: string }>()
  const { setCharity } = useCheckoutPlan()
  const queryParams = new URLSearchParams(useLocation().search)
  const userId = queryParams.get('user_id')

  const { isLoading, data: charity } = useQuery(
    ['charity', charityID],
    async () => await loadCharityCampaign(ActiveCampaign.myTenNights, charityID),
    {
      onSuccess: (data) => {
        setCharity(data)
        MTNtracking.setPartnerCharitySite(data.charity_name, data.charity_id, userId)
      },
      onError: () => {
        history.push(Navigation.home)
      },
      retry: 0
    }
  )

  return <WithFooter>
    <Header transparent />
    <section className="bg-gradient-to-t from-white min-h-600 via-mtn-gray-300 to-mtn-gray-100">
      <Page className="flex flex-col items-center justify-center text-center min-h-600 pt-20 pb-10 bg-top bg-no-repeat bg-cover mountain-sky-full">
        {!isLoading && <CharityLogo charity={charity} />}
        <div className='flex flex-col py-8'>
        <LandingPageContent />
        </div>
        {isLoading && <div className="flex justify-center mt-20"><Spinner className="text-mtn-blue" /></div>}
        {(isCampaignOpen() && !isLoading) && <Split nextPage={nextPage} isCharity={true}/>}
        {!isCampaignOpen() && <SubscribeForm />}
      </Page>
    </section>
  </WithFooter>
}
