import { type FC } from 'react'
import { Header, WithFooter } from '~/components'
import { EmailXL, Page, PhoneXL } from '@lib/components'

import nightSky from '~/img/night-sky.jpg'

export const Contact: FC = () => {
  return <WithFooter>
    <Header transparent />
    <section className="bg-top bg-cover min-h-600 md:min-h-screen" style={{ backgroundImage: `url(${nightSky})` }}>
      <Page skinny className="px-10 pt-24 pb-24 font-medium text-white min-h-700 md:min-h-screen">
        <h1 className="mt-6 text-2xl font-bold md:text-4xl">
          Need some help?
        </h1>
        <p className="mt-2 md:text-xl md:mt-4">
          Here's how you can get in touch.
        </p>

        <div className="bg-mtn-blue-500 my-8 h-0.5 md:my-12"></div>

        <div className="md:flex md:items-start">
          <span className="inline-block rounded-full bg-mtn-blue-500">
            <EmailXL />
          </span>
          <div className="mt-2 md:mt-0 md:flex-1 md:ml-8">
            <p><strong>Email us</strong> for donor enquiries.</p>
            <p className="mt-2 font-bold md:mt-4"><a href="mailto:contact@mytennights.com">contact@mytennights.com</a></p>
          </div>
        </div>
        <div className="mt-8 md:flex md:items-start">
          <span className="inline-block rounded-full bg-mtn-blue-500">
            <EmailXL />
          </span>
          <div className="mt-2 md:mt-0 md:flex-1 md:ml-8">
            <p><strong>Email us</strong> for charity enquiries.</p>
            <p className="mt-2 font-bold md:mt-4"><a href="mailto:contact@mytennights.com">contact@shamaazi.com</a></p>
          </div>
        </div>
        <div className="mt-8 md:flex md:items-start">
          <span className="inline-block rounded-full bg-mtn-blue-500">
            <PhoneXL />
          </span>
          <div className="mt-2 md:mt-0 md:flex-1 md:ml-8">
            <p><strong>Call us</strong> for customer service support.</p>
            <p className="mt-2 font-bold md:mt-4 select-none">+44 204 541 6388</p>
          </div>
        </div>
        <div className="">
            <p className="mt-10 md:mt-10 text-sm md:text-lg">Our opening hours are Monday to Sunday from 9:00 AM to 5:00 PM, every day.</p>
          </div>
      </Page>
    </section>
  </WithFooter>
}
