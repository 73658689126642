import { type FC, useEffect } from 'react'
import { Header, Navigation, SubscribeForm, WithFooter } from '~/components'
import { isCampaignOpen, MTNtracking, useCheckoutPlan, type CharityCampaign } from '@shamaazi/mytennights'

import { HomeHeadline } from './HomeHeadline'

import { useQuery } from 'react-query'
import { getCharityFromDonation } from '~/service'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { GMErrorMessage } from '~/components/GMErrorMessage'
import { Partner } from '~/service/partners'
import { Split } from './Split'

interface DonationSplitProps {
  nextPage: string
}

export enum ButtonLocation {
  TopBar = 'top_bar',
  Main = 'main',
  Bottom = 'bottom'
}

const charityParamKey = 'charity'

export const Home: FC<DonationSplitProps> = ({ nextPage }) => {
  const history = useHistory()
  const searchParams = new URLSearchParams(window.location.search)
  const charitySearchParam = searchParams.get(charityParamKey)
  const { chainCode, partnerName } = useParams<{ chainCode?: string, partnerName?: string }>()
  if (partnerName) {
    if (Partner.allowedPartners.map(p => p.name).includes(partnerName.toLowerCase())) {
      // set the partner name
      MTNtracking.setPartnerCharitySite(null, null, null, partnerName.toLowerCase())
    } else {
      // redirect to the home page
      history.push(Navigation.home)
      MTNtracking.setPartnerCharitySite(null, null, null, '')
    }
  }
  const { plan, setPlan } = useCheckoutPlan()

  const { error: parentCharityError } = useQuery<CharityCampaign | '' | undefined, Error>(
    ['parentCharity', chainCode],
    async () => chainCode && await getCharityFromDonation(chainCode), {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        setPlan({ ...plan, chainCode: chainCode ?? '', chainCodeCharity: data })
        if (data?.partner) {
          MTNtracking.setPartnerCharitySite(null, null, null, data.partner)
        }
        if (data?.voucher_eligible && data?.voucher_type) {
          MTNtracking.setParentVoucher(data.voucher_eligible, data.voucher_type)
        }
      },
      onError: () => {
        setPlan({ ...plan, chainCode: '' })
      }
    })

  useEffect(() => {
    MTNtracking.setPartnerCharitySite(null, null, null, partnerName?.toLowerCase())
  }, [partnerName])

  // If query param is charity then redirect to charity landing page. This is done to aid Google Ads approval
  if (charitySearchParam) {
    searchParams.delete(charityParamKey)
    return <Redirect to={`/${charitySearchParam}?${searchParams.toString()}`}/>
  }
  return <WithFooter navigation={true}>
    <Header transparent showProfileLink />
    <div
      className={'flex flex-col lg:justify-center items-center gap-y-20 md:gap-y-10 pt-24 bg-top bg-no-repeat bg-cover mountain-sky-full'}>
      <HomeHeadline />
      {isCampaignOpen() && <GMErrorMessage error={parentCharityError} chainCode={chainCode}/>}
      {isCampaignOpen() && <Split nextPage={nextPage} />}
      {!isCampaignOpen() && <SubscribeForm/>}
    </div>
  </WithFooter>
}
