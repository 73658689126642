import { type FC, useEffect, useRef, useState, type ReactElement } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  type Cause,
  useCheckoutPlan,
  isMobileScreen,
  getMinimalPlanAmountText,
  isBreakdownValid,
  MTNtracking,
  mtnSplitTexts,
  useAuthWithSignup,
  getSplitBreakdown,
  DafDisclaimer,
  ProductName,
  CharityDisclaimer,
  isCharityNotManagedByDaf,
  getToggleButtonStyle, displayableStartDate, CampaignSetup,
  getGiftAidLongCopy,
  ScrollHash
} from '@shamaazi/mytennights'
import { CheckoutProgress, Navigation, Pages, WithFooter } from '~/components'
import { Button, GiftAid, Modal, Page, Validate, Input } from '@lib/components'
import { type Currency, displayCurrency, Country, getCurrencySymbol, GetCurrencyByString } from '@lib/services'
import { MytennightsTestId, PaymentFormTestId } from '@lib/testing'

import CurrencyInput from 'react-currency-input-field'

import { Header } from '~/components/header'
import editIcon from '~/img/edit.svg'
import arrowRight from '~/img/arrow-right.svg'
import { Split } from '../home/Split'
import { DateTime } from 'luxon'
import { scrollToHash } from '@shamaazi/mytennights'

interface CauseInputProps {
  cause: Cause
  value: number
  currency: Currency
  onChange: (v: number) => void
  trackEvent: () => void
}

const CauseInput: FC<CauseInputProps> = ({ cause, value, onChange, currency, trackEvent }) => {
  const input = useRef<HTMLInputElement>(null)

  const updateAmount = (value: string): void => {
    const n = parseFloat(value)
    const v = (!isNaN(n) && n > 0 && Math.round(n * 100)) || 0
    if (v < 0 || v > 99999999) {
      return
    }
    (v) /* eslint-disable-line @typescript-eslint/no-unused-expressions */
    onChange(v)
  }

  return <div
    data-test-id={MytennightsTestId.cause_ + cause.title.toLowerCase()}
    className={'font-medium col-span-1'}>
    <div className="flex flex-row gap-y-6 py-3 pl-5 pr-3 justify-between items-center bg-mtn-blue-100 rounded-lg h-full">
      <h2 className=" text-lg md:text-xl font-bold text-left text-mtn-blue-800 w-5/12 md:w-7/12">{cause.title}</h2>
      <form className="flex bg-white flex-row form-border-gray rounded-md w-5/12 md:w-4/12">
        <span className='w-2/5 flex items-center px-3 text-xl font-semibold text-right focus:outline-none '>{getCurrencySymbol(GetCurrencyByString(currency))}</span>
        <CurrencyInput
          data-test-id={MytennightsTestId.causeInput_ + cause.title.toLowerCase()}
          defaultValue={value > 0 ? value / 100 : ''}
          id="cause amount"
          className="w-3/4 text-xl font-bold rounded-md p-2 py-1 focus:outline-none text-gm-black"
          placeholder={'0.00'}
          step={0.01}
          onValueChange={(value) => updateAmount(value ?? '')}
          ref={input}
          onBlur={trackEvent}
          intlConfig={{ locale: 'en-UK' }}
        />
      </form>
    </div>
  </div>
}

export const Causes: FC<{ nextPage: string }> = ({ nextPage }) => {
  const { user, logout } = useAuthWithSignup()
  const { plan, setPlan } = useCheckoutPlan()
  const [openBreakdown, setOpenBreakdown] = useState(false)
  const [openSplit, setOpenSplit] = useState(false)
  const [email, setEmail] = useState(plan.donorDetails.email)
  const history = useHistory()
  const donationTotal = Object.values(plan.causes).reduce((acc, v) => acc + v, 0)
  const [isLowAmount, setIsLowAmount] = useState<boolean>(false)
  const ramadanStartDateOffsets = [0, 1]
  const [offsetSelected, setOffsetSelected] = useState<number>(0)
  const setCauseValue = (causeID: string, value: number): void => {
    setPlan({ ...plan, causes: { ...plan.causes, [causeID]: value } })
  }
  const [isMobile, setIsMobile] = useState(isMobileScreen())
  useEffect(() => {
    window.addEventListener('resize', () => setIsMobile(isMobileScreen()))
    if (user?.email) {
      setEmail(user.email)
      setPlan({ ...plan, donorDetails: { ...plan.donorDetails, email: user.email } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email])

  const SummaryCard: FC<{ setOpenBreakdown: (status: boolean) => void, setOpenSplit: (status: boolean) => void }> = ({ setOpenBreakdown, setOpenSplit }) => {
    const card = useRef<HTMLDivElement>(null)
    const donationTotal = Object.values(plan.causes).reduce((acc, v) => acc + v, 0)
    const [enlarge, setEnlarge] = useState<boolean>(false)
    useEffect(() => {
      setEnlarge(true)
      setTimeout(() => {
        setEnlarge(false)
      }, 200)
    }, [donationTotal])

    return <div ref={card}
      className={'sm:min-h-250 p-4 flex flex-col gap-y-4 items-center justify-around rounded-lg mountain-sky-full text-mtn-white'}>
      <span className={`${enlarge ? 'enlarge' : ''} font-bold text-4xl lg:text-6xl`}>{displayCurrency({
        amount: donationTotal,
        currency: plan.charity.currency
      })}</span>
      <h1 className="text-sm font-light uppercase">Your total donation amount </h1>
      <div className='text-white text-sm flex flex-row justify-between'>
        <div>
          <span className='font-bold'> {plan.totalNight.value} nights</span> <span> - {mtnSplitTexts[plan.split]?.toUpperCase()}</span>
        </div>
        <img src={editIcon} alt='edit icon' className='w-6 h-6 ml-4 cursor-pointer' onClick={() => setOpenSplit(true)} />
      </div>
      <div className="text-sm font-semibold underline cursor-pointer" onClick={() => setOpenBreakdown(true)}> See daily breakdown</div>
      {!isMobile && renderContinueBtn()}
    </div>
  }

  const causes = plan.charity.causes.map((cause) => {
    const value = plan.causes[cause.cause_id] ?? 0
    return <CauseInput
      key={cause.cause_id}
      cause={cause}
      currency={plan.charity.currency}
      value={value}
      onChange={(value) => {
        setCauseValue(cause.cause_id, value)
      }}
      trackEvent={() => {
        MTNtracking.addCause(user?.email, cause.title, value, plan)
      }
      }
    />
  })

  const renderGiftAid = (): ReactElement => {
    return <GiftAid
      className="p-4 rounded-lg bg-mtn-blue-100"
      charityName={plan.charity.charity_name}
      checkbox={<input type="checkbox" checked={plan.paymentDetails.giftAid} onChange={(val) => setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, giftAid: val.target.checked } })} className='rounded-full md:w-4 md:h-4 w-3 h-3' />}
      longCopy={getGiftAidLongCopy(plan.charity)}>
      <h3 className="text-lg text-mtn-blue-800">GiftAid it?
      </h3>
    </GiftAid>
  }

  const renderEmail = (): ReactElement => {
    return <div className='p-4 flex flex-col rounded-lg bg-mtn-blue-100'>
      <h2 className="text-lg font-bold text-mtn-blue-800">Contact Details</h2>
      <form className="flex flex-col items-start mt-3">
        <label className='text-mtn-blue-900'>Email</label>
        <Input
          autoComplete='email'
          type='email'
          id="email address"
          data-test-id={MytennightsTestId.emailInput}
          className="w-full text-md rounded-md p-2 py-1 border-1 border-sz-gray-400"
          value={user?.email ?? plan.donorDetails.email}
          onChange={(e) => {
            setEmail(e.target.value); setPlan({ ...plan, donorDetails: { ...plan.donorDetails, email: e.target.value } })
          }}
          onBlur={() => {
            MTNtracking.checkOutStarted(user?.email, plan)
          }}
          placeholder={'Enter your email'} variant={'mtn'} />
      </form>
      <p className='text-sm text-right text-mtn-blue-900 mt-2'> We'll email you nightly receipts</p>
      {user !== null && <p className="mt-2 text-xs text-right">
        If you wish to use another email address please <Link
          className="underline text-mtn-blue" to="#" onClick={() => {
            logout()
          }}>log out</Link> first
      </p>}
      <p data-test-id={PaymentFormTestId.emailError}
        className="mt-1 text-xs text-right text-mtn-red min-h-xs">{Validate.validateEmail(email) === true ? '' : 'Please enter a valid email address'}</p>
    </div>
  }

  const renderRamadanStartDate = (): ReactElement => {
    const getDetailsToggleButtonStyle = (isToggle: boolean): string => `${getToggleButtonStyle(isToggle)} w-1/3 md:text-lg text-xs`
    return <section className="bg-mtn-blue-100 p-6 rounded-lg">
      <div className='text-mtn-blue-900 flex flex-row relative justify-between mb-4'>
        <div className='flex flex-col'>
          <h2 className="text-lg font-bold text-mtn-blue-800">{CampaignSetup.defaultRamadanStartDate.diffNow('days').days > 0 ? 'Your Ramadan start date' : 'When did you start fasting?'}</h2>
          <p className='text-sm text-left text-mtn-blue-900'>We'll make sure your payments are taken on the correct nights</p>
        </div>
      </div>
      <div className="flex w-full justify-start items-stretch h-12 gap-x-2">
        {ramadanStartDateOffsets.map(offset => <button
            name="ramadan-start"
            data-test-id={MytennightsTestId.ramadanOffsetButton_ + offset.toString()}
            key={offset.toString()}
            onClick={() => {
              setPlan({ ...plan, startDateOffset: offset })
              setOffsetSelected(offset)
            }}
            className={getDetailsToggleButtonStyle(offsetSelected === offset)}
          >{displayableStartDate(offset)}</button>
        )}
      </div>
    </section>
  }
  const renderBreakdown = (status: boolean): ReactElement => {
    const nightBreakdown = getSplitBreakdown(plan)

    return <Modal isOpen={status} onClose={() => setOpenBreakdown(false)}>
      <div className='pb-8 px-4 overflow-auto max-h-96 '>
        <h2 className='font-bold text-mtn-blue-800 my-2'>Daily Donation Breakdown</h2>
        {nightBreakdown.nights.map(d => {
          return (<div key={d.number} className='flex justify-between gap-x-8 border-b-1 border-mtn-gray-300 py-2 text-mtn-blue-800'>
            {/* we add a +1 so the first night is set as Night 1 (on the frontend) instead of Night 0 (which it is ideally) Night 0 means the first night (First Taraweeh Night) before the first fasting day. */}
            <p>Night {d.number} - {d.date.toLocaleString(DateTime.DATE_MED)}</p>   <p>{displayCurrency({ amount: d.amount, currency: plan.charity.currency })}</p>
          </div>)
        })}
        <div className='text-mtn-blue-800 py-2'>
        If there are 30 nights this year, we'll take a payment that night equal to your 28th night payment.
        </div>
      </div>
    </Modal>
  }

  const renderSplit = (status: boolean): ReactElement => {
    return <Modal isOpen={status} onClose={() => setOpenSplit(false)}>
      <div className='py-8 px-4 mountain-sky-full'>
        <Split nextPage={'payment'} onClick={() => setOpenSplit(false)} />
      </div>
    </Modal>
  }

  const renderContinueBtn = (): ReactElement => {
    return <div className='flex w-full my-4 justify-center'>
      <Button
        data-test-id={MytennightsTestId.continueButton}
        variant="mtn-donate-now"
        className='bg-gm-yellow button-disabled-color text-mtn-blue-800 text-2xl py-3 w-72  uppercase font-bold '
        disabled={!(donationTotal > 0 && donationTotal <= 99999999) || Validate.validateEmail(email) !== true}
        onClick={() => {
          const isValid = isBreakdownValid(plan)
          setIsLowAmount(!isValid)
          if (isValid) {
            setPlan(plan)
            MTNtracking.confirmAmount(user?.email, plan)
            const partner = MTNtracking.getPartner().partner
            history.push(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
          }
          if (isLowAmount) {
            scrollToHash(ScrollHash.lowAmountEntered)
          }
        }}
      >
        <div className='flex gap-x-4 justify-center'>
          Continue <img src={arrowRight} alt='continue' />
        </div>
      </Button>
    </div>
  }

  return <WithFooter navigation={false}>
    <Header />
    <CheckoutProgress step={Pages.Causes} />
    <Page className="md:flex md:justify-center">
      {renderBreakdown(openBreakdown)}
      {renderSplit(openSplit)}
      <div className="py-4 md:px-10 px-4 sm:px-0 flex flex-col justify-start gap-8 lg:w-4/5">
        <div className="flex flex-row gap-4">
          <div className="w-full flex justify-center">
            <h1 className="text-2xl w-96 text-center lg:text-4xl font-semibold text-mtn-blue-800">How should we split your donations?</h1>
          </div>
        </div>
        <div className='md:grid lg:grid-cols-2 md:grid-cols-2 md:gap-x-6'>
          <div className={'flex flex-col gap-y-4 '}>
            <div className='flex flex-col gap-y-4'>
              {causes}
            </div>
            {isLowAmount && <p id={ScrollHash.lowAmountEntered} className="mt-1 text-s text-center text-mtn-red min-h-xs">{`Amount entered is too low, minimal amount is ${getMinimalPlanAmountText(plan)}`}</p>}
            {isMobile && <SummaryCard setOpenBreakdown={setOpenBreakdown} setOpenSplit={setOpenSplit} />}
            {plan.charity.country === Country.GB && renderGiftAid()}
            {renderRamadanStartDate()}
            {renderEmail()}
            {renderContinueBtn()}
          </div>
          <div>
            {!isMobile && <SummaryCard setOpenBreakdown={setOpenBreakdown} setOpenSplit={setOpenSplit} />}
          </div>
        </div>
        {isCharityNotManagedByDaf(plan.charity.charity_id) ? <CharityDisclaimer charity={plan.charity}/>
          : <DafDisclaimer country={plan.charity.country} product={ProductName.myTenNights} charityId={plan.charity.charity_id} />
        }

      </div>
    </Page>
  </WithFooter>
}
